<template>
    <div class="mb-3" :id="typeof $attrs.id === 'string' ? 'wrapper-input-' + $attrs.id : null" v-if="!disabled" v-bind:class="{ 'd-none':  $attrs.type === 'hidden', 'disable-selection' : disableSelection }">
        <ValidationProvider tag="div" :vid="typeof $attrs.id === 'string' ? $attrs.id : null" :rules="disabled ? null : rules" v-slot="{
            valid,
            errors
        }">
          <label class="form-label" v-if="needLabel" :for="typeof $attrs.id === 'string' ? $attrs.id : null">{{ getLabel(typeof label === 'string' && label !== null && label.trim() !== '' ? label : $attrs.id) }} <span class="text-danger" v-if="isRequired(rules)">*</span></label>
          <template v-if="!hasSlot('content_' + $attrs.id)">
            <template v-if="$attrs.type === 'price'">
              <b-form-input
              :tabindex="disableSelection ? -1 : null"
              :disabled="disabled"
              type="text"
              @keydown="handlePriceInput($event)"
              :placeholder="typeof $attrs.placeholder === 'string' ? $attrs.placeholder : 'Ketikkan ' + getLabel(typeof label === 'string' && label !== null && label.trim() !== '' ? label : $attrs.id)"
              v-on="listeners"
              v-bind:value="value"
              :state="rules !== null ? (errors.length ? valid : null) : null"
              v-bind="$attrs"></b-form-input>
            </template>
            <template v-else-if="$attrs.type === 'password'">
              <div style="position: relative;">
                <b-form-input
                autocomplete="off"
                :type="showInput ? 'text' : 'password'"
                :disabled="disabled"
                :placeholder="typeof $attrs.placeholder === 'string' ? $attrs.placeholder : 'Ketikkan ' + getLabel(typeof label === 'string' && label !== null && label.trim() !== '' ? label : $attrs.id)"
                v-on="listeners"
                v-bind:value="value"
                :state="rules !== null ? (errors.length ? valid : null) : null"
                v-bind="$attrs"></b-form-input>
                <b-button variant="link" class="password-eye" @click.prevent="togglePassword">
                  <font-awesome-icon :icon="showInput ? 'eye-slash' : 'eye'"></font-awesome-icon>
                </b-button>
                <div class="invalid-feedback d-block" v-if="errors.length > 0">{{ errors[0] }}</div>
              </div>
            </template>
            <template v-else-if="$attrs.type === 'textarea'">
              <b-form-textarea
              :disabled="disabled"
              type="text"
              :placeholder="typeof $attrs.placeholder === 'string' ? $attrs.placeholder : 'Ketikkan ' + getLabel(typeof label === 'string' && label !== null && label.trim() !== '' ? label : $attrs.id)"
              v-on="listeners"
              v-bind:value="value"
              :state="rules !== null ? (errors.length ? valid : null) : null"
              v-bind="$attrs"></b-form-textarea>
            </template>
            <template v-else-if="$attrs.type === 'date'">
              <div class="w-100"></div>
              <date-picker 
              :placeholder="typeof $attrs.placeholder === 'string' ? $attrs.placeholder : getLabel(typeof label === 'string' && label !== null && label.trim() !== '' ? label : $attrs.id)"
              v-bind:class="{ 'is-invalid':  rules !== null && errors.length }"
              :state="rules !== null ? (errors.length ? valid : null) : null"
              class="form-control"
              format="DD-MM-YYYY" @change="datepickerValueChanged" v-model="datepicker"></date-picker >
              <b-form-input
              type="text"
              style="display: none;"
              :placeholder="typeof $attrs.placeholder === 'string' ? $attrs.placeholder : 'Ketikkan ' + getLabel(typeof label === 'string' && label !== null && label.trim() !== '' ? label : $attrs.id)"
              v-on="listeners"
              v-bind:value="value"
              :state="rules !== null ? (errors.length ? valid : null) : null"
              v-bind="$attrs"></b-form-input>
            </template>
            <!-- <template v-else-if="$attrs.type === 'enum'">
              <b-form-select
              :disabled="disabled"
              v-on="listeners"
              v-bind:value="value"
              :state="rules !== null ? (errors.length ? valid : null) : null"
              v-bind="$attrs"></b-form-select>
            </template> -->
            <template v-else-if="$attrs.type === 'enum'">
              <input type="text" class="form-control" disabled v-show="$attrs.options.length === 0">
              <v-select
                v-show="$attrs.options.length > 0"
                :placeholder="typeof $attrs.placeholder === 'string' ? $attrs.placeholder : 'Ketikkan ' + getLabel(typeof label === 'string' && label !== null && label.trim() !== '' ? label : $attrs.id)"
                :class="{'is-invalid': (rules !== null ? (errors.length ? true : false) : false)}" 
                :disabled="disabled"
                v-on="listeners"
                :reduce="option => option.value"
                v-bind:value="value"
                v-bind="$attrs">
                  <!-- <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      :state="rules !== null ? (errors.length ? valid : null) : null"
                    />
                  </template> -->
              </v-select>
            </template>
            <template v-else-if="$attrs.type === 'radio'">
              <b-form-group>
                <b-form-radio-group
                  :disabled="disabled"
                  v-on="listeners"
                  @input="updateValue"
                  :state="rules !== null ? (errors.length ? valid : null) : null"
                  v-bind="$attrs"
                  :rules="null"
                  :checked="value"
                  :name="$attrs.id"
                ></b-form-radio-group>
                <input type="text" style="visibility: hidden;position: absolute;z-index: -1;" :rules="$attrs.rules ? $attrs.rules : null" v-bind:value="value">
              </b-form-group>
            </template>
            <template v-else-if="$attrs.type === 'hidden'">
              <input type="text"
              v-on="listeners"
              v-bind:value="value"
              :state="rules !== null ? (errors.length ? valid : null) : null"
              v-bind="$attrs">
            </template>
            <template v-else-if="$attrs.type === 'image'">
              <b-form-file
              :state="rules !== null ? (errors.length ? valid : null) : null"
                accept="image/png,image/jpeg"
                placeholder="Pilih file disini..."
                drop-placeholder="Drop file disini..."
                v-on="listeners"
                v-bind:value="value"
                v-bind="$attrs"
              ></b-form-file>
            </template>
            <template v-else-if="$attrs.type === 'attachment'">
              <b-form-file
                :state="Boolean(value)"
                accept="image/png,image/jpeg,application/pdf"
                placeholder="Pilih file disini..."
                drop-placeholder="Drop file disini..."
                v-on="listeners"
                v-bind:value="value"
                v-bind="$attrs"
              ></b-form-file>
            </template>
            <template v-else-if="$attrs.type === 'attachmentExcel'">
              <b-form-file
                :state="Boolean(value)"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                placeholder="Pilih file disini..."
                drop-placeholder="Drop file disini..."
                v-on="listeners"
                v-bind:value="value"
                v-bind="$attrs"
              ></b-form-file>
            </template>
            <template v-else-if="$attrs.type === 'number'">
              <b-form-input
              type="number"
              min="1"
              :disabled="disabled"
              :placeholder="typeof $attrs.placeholder === 'string' ? $attrs.placeholder : 'Ketikkan ' + getLabel(typeof label === 'string' && label !== null && label.trim() !== '' ? label : $attrs.id)"
              v-on="listeners"
              v-bind:value="value"
              :state="rules !== null ? (errors.length ? valid : null) : null"
              v-bind="$attrs"></b-form-input>
            </template>
            <template v-else>
              <b-form-input
              :disabled="disabled"
              :placeholder="typeof $attrs.placeholder === 'string' ? $attrs.placeholder : 'Ketikkan ' + getLabel(typeof label === 'string' && label !== null && label.trim() !== '' ? label : $attrs.id)"
              v-on="listeners"
              v-bind:value="value"
              :state="rules !== null ? (errors.length ? valid : null) : null"
              v-bind="$attrs"></b-form-input>
            </template>
          </template>
          <template v-else>
            <slot v-if="hasSlot('content_' + $attrs.id)" :name="'content_' + $attrs.id" :col-data="value"/>
          </template>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </ValidationProvider>
    </div>
</template>
<script>
  import { isRequired, formatIdrInput } from '@/_helpers'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'
  import 'vue2-datepicker/locale/id'

  export default {
    components: { DatePicker },
    inheritAttrs: false,
    name: 'fg-input',
    props: {
      value: [String, Number, File, Object],
    //   value: {
    //     type: String, Number,
    //     required: true
    //   },
      disableSelection: {
        type: Boolean,
        required: false,
        default: function () {
          return false
        }
      },
      needLabel: {
        type: Boolean,
        required: false,
        default: function () {
            return false
        }
      },
      // type: {
      //   type: String,
      //   required: true
      // },
      label: {
        type: String,
        required: false,
        default: function () {
            return null
        }
      },
      rules: {
        type: String,
        required: false,
        default: function () {
            return null
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: function () {
          return false
        }
      },
      // options: {
      //   type: Array,
      //   required: false,
      //   default: function () {
      //     return []
      //   }
      // }
    },
    data () {
      return {
        fileInput: null,
        datepicker: this.value,
        showInput: false,
      }
    },
    mounted () {
      // console.log(typeof this.$attrs.id === 'string')
    },
    watch: {
      'disabled': function (newValue, oldValue) {
        if (newValue) {
          this.$emit('input', null)
        }
      },
      // 'fileInput': function (newValue, oldValue) {
      //   this.$emit('input', newValue)
      // }
      
    },
    computed: {
      listeners () {
        return {
          ...this.$listeners,
          // input: this.updateValue,
        //   focus: typeof this.onFocus === 'function' ? this.onFocus : null,
        //   blur: typeof this.onBlur === 'function' ? this.onFocus : null,
          // change: this.onChange
        }
      }
    },
    methods: {
      togglePassword () {
        this.showInput = !this.showInput
      },
      hasSlot (name = 'default') {
        // if (typeof this.$slots[name] === 'undefined') {
        //   return false
        // }
        return !!this.$slots[name] || !!this.$scopedSlots[name]
      },
      setValue (value) {
        if (this.$attrs.type === 'date') {
          this.datepicker = value !== null ? new Date(value) : null
        } 
        this.$nextTick(() => {
          this.$emit('input', value)
        })
      },
      handlePriceInput (evt) {
        setTimeout(() => {
          formatIdrInput(evt.target.value).then(resp => {
            this.$emit('input', resp)
          })
        }, 10)
      },
      isRequired (rules) {
        return isRequired(rules)
      },
      updateValue (evt) {
        let value = typeof evt === 'string' ? evt : evt.target.value
        if (!this.touched && value) {
          this.touched = true
        }
        this.$emit('input', value)
      },
      forceUpdateValue (value) {
        if (!this.touched && value) {
          this.touched = true
        }
        this.$emit('input', value)
      },
      onFocus (value) {
        this.focused = true
        this.$emit('focus', value)
      },
      onBlur (value) {
        this.focused = false
        this.$emit('blur', value)
      },
      onChange (element) {
        this.$emit('change', element.target.value)
      },
      getLabel (value) {
        return value !== null ? value.replace(/_/g, ' ') : null
      },
      datepickerValueChanged (val) {
        this.$emit('input', val !== null ? this.$moment(val).format('YYYY-MM-DD') : null)
      }
    }
  }
</script>